<!-- 添加走访弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="25"
      :title="title"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 基本信息 -->
      <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;" layout="vertical" title="基本信息">
        <a-descriptions-item label="姓名">
          {{
            personInforList.residentName ? personInforList.residentName : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{
            personInforList.identityCard ? personInforList.identityCard : "--"
          }}
        </a-descriptions-item>
        <a-descriptions-item label="性别">
          {{ personInforList.gender }}
        </a-descriptions-item>
        <a-descriptions-item label="年龄">
          {{ personInforList.age }}
        </a-descriptions-item>
        <a-descriptions-item label="出生日期">
          {{ personInforList.birthDate }}
        </a-descriptions-item>
        <a-descriptions-item label="联系电话">
          {{ personInforList.phone ? personInforList.phone : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="现住区域">
          {{ personInforList.currentArea ? personInforList.currentArea : "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="详细地址" :span="2">
          {{
            personInforList.cellName
              ? `${personInforList.cellName}/${personInforList.buildingNum}/${personInforList.unitNum}/${personInforList.floorNum}/${personInforList.accountNum}`
              : "--"
          }}
        </a-descriptions-item>

        <a-descriptions-item label="照片">
          <imagePreview ref="img1" :businessId="businessId"></imagePreview>
        </a-descriptions-item>
      </a-descriptions>
      <!-- 左边竖线样式写法 -->
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
        "
      >
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">走访记录</div>
        </div>
      </div>

      <!-- 基本信息 -->
      <ax-form ref="form" :formBuilder="formBuilder">
        <div slot="file1">
          <imageUpload ref="img2" :businessId="businessId1"></imageUpload>
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="onSubmit"
          style="background-color: #556bff"
          >保 存</el-button
        >
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const formList = [
  {
    label: "走访时间",
    type: "datePicker",
    model: "interviewTime",
    options: { placeholder: "请选择" },
    col: { span: 12 },
    rules: [{ required: true, message: "请选择走访时间" }],
  },
  {
    label: "现实状态",
    type: "select",
    model: "actualState",
    options: {},
    col: { span: 12 },
    rules: [{ required: true, message: "请选择现实状态" }],
  },
  {
    label: "走访事项",
    type: "textarea",
    model: "interviewMatters",
    options: { placeholder: "请输入", minRows: 2, maxLength: 200 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入走访事项" }],
  },
  {
    label: "走访描述",
    type: "textarea",
    model: "interviewDescription",
    options: { placeholder: "请输入", minRows: 2, maxLength: 400 },
    col: { span: 24 },
    rules: [{ required: false }],
  },
  {
    label: "图片",
    type: "",
    model: "file1",
    options: { placeholder: "请输入" },
    col: { span: 24 },
    rules: [{ required: false }],
  },
];
import api from "./api";
import imageUpload from "./ax-image-modal copy/src/image-upload.vue";
import imagePreview from "./image-preview/imagePreview.vue";
export default {
  components: {
    imagePreview,
    imageUpload,
  },
  data() {
    return {
      title: "添加走访",
      businessId: "",
      businessId1: "",
      visible: false,
      fileIds: [], //图片数组
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 150 },
        { col: { span: 8 } }
      ),
      personInforList: {},
    };
  },
  props: ["id"],
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.businessId = this.id;
      this.$nextTick(() => {
        this.getPersonInfo();
        this.getDictionsary();
        this.$refs.img2.removeFileList()
      });
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$refs.form.resetFields();
      this.$emit("refsh");
    },
    // 获取字典
    async getDictionsary() {
      // 走访现实状态
      await api.dictData({ dicKind: "interview_actual_state" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.form.setFormItemProp("actualState", {
          options: { options, allowClear: true, placeholder: "请选择" },
        });
      });
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.personInforList.age = info.age;
      return info;
    },
    // 提交
    async onSubmit() {
      this.$refs.form.form.validateFields(async (err, value) => {
        if (err) return;
        this.fileIds=[]
        value.residentId = this.businessId;
        value.type = "2";
        const files = this.$refs.img2.getFile();
        console.log(files, "185");
        if (files.length > 0) {
          files.map((item) => {
            if (item.response) {
              this.fileIds.push(item.response.data.id);
            } else {
              this.fileIds.push(item.uid);
            }
          });
          value.fileIds = [...new Set([...this.fileIds])];
        }
        const res = await api.addInterviewInfo(value);
        console.log(res);
        if (res.status === 200) {
          this.$message.success("新增成功");
          this.closeModal();
        }
      });
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取居民信息详情
    async getPersonInfo() {
      const res = await api.getResidentArchivesById(this.id);
      console.log(res, "回显详情");
      this.personInforList = res.data;
      this.calculateIDCardInfo(res.data.identityCard);
    },
    //   // 根据id获取精神病人信息
    //   async getPsychiatricPatientsInfo(id) {
    //       const res = await api.getKeyPopulationById(id)
    //       console.log(res, '回显精神病人详情');
    //       this.$refs.form.setFieldsValue(res.data.residentArchives);
    //       this.$refs.form1.setFieldsValue(res.data.psychopath);
    //       this.$refs.form2.setFieldsValue(res.data.psychopath);
    //       this.$refs.form3.setFieldsValue(res.data.psychopath);
    //       this.calculateIDCardInfo(res.data.residentArchives.identityCard)
    //   }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.dialog-footer {
  display: flex;
  justify-content: end;
}

.search-title {
  // font-size: 0.072rem;
  font-weight: 800;
}
</style>